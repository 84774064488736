import { PrivacyStatus, RoleId } from '@wix/members-domain-ts';

import {
  InjectedComputedProps,
  Nullable,
  DataHook,
  NumberStyleParam,
  PublicMember,
  Experiments,
} from '../types';
import { FlowAPI, WixExperiments } from '../types/controller';
import { RootState } from './root-reducer';
import {
  defaultProfileImageLayout,
  defaultProfileLayout,
} from '../components/ProfileCard/stylesParams';
import { getBadgeSettingsProps } from '../services/badge-layout';

const getStyleParams = ({ componentSettings: { styleParams } }: RootState) => {
  return styleParams;
};

const getProfileLayout = (state: RootState) => {
  const { numbers } = getStyleParams(state);
  const profileLayout = numbers[NumberStyleParam.ProfileLayout];

  return profileLayout ?? defaultProfileLayout;
};

const getProfileStyle = (state: RootState) => {
  const pictureStyle = getStyleParams(state).numbers.pictureStyle;
  return pictureStyle ?? defaultProfileImageLayout;
};

const showAllBadges = ({ site, users }: RootState) => {
  const badgesTabOpened = site.settingsTab === DataHook.BadgesTabButton;
  const hasBadgesAssigned = users.viewed.badges.length;

  return badgesTabOpened && !hasBadgesAssigned;
};

const getAssignedBadges = (state: RootState) => {
  const { users, badges } = state;
  const allBadges = badges.list;
  const viewedMemberBadges = users.viewed.badges;

  if (showAllBadges(state)) {
    return allBadges;
  }

  return allBadges.filter(({ id }) => {
    return viewedMemberBadges.includes(id);
  });
};

const getBadgeSettings = (
  language: string,
  state: RootState,
): InjectedComputedProps['badgesSettings'] => {
  const { componentSettings, globalSettings } = state;

  return {
    ...getBadgeSettingsProps(componentSettings, globalSettings),
    align: 'center',
    badges: getAssignedBadges(state),
    language,
    maxRows: 1,
    useTextRemainder: false,
  };
};

const getInCommunity = ({ users }: RootState) =>
  isMemberInCommunity(users.viewed);

const getRolesActions = (rootState: RootState) => {
  const { users, site, roles } = rootState;
  const { rolesActions } = users.viewed;
  const shouldHideEdit = getInCommunity(rootState) && site.isSocial;
  const memberRoleActions = rolesActions ?? [];

  const filteredRoleActions = memberRoleActions
    .filter((action) => !shouldHideEdit || action.roleId !== RoleId.EDIT)
    .filter((action) => action.roleId !== RoleId.JOIN_COMMUNITY);

  return roles.map
    ? filteredRoleActions.map((action) => ({
        ...action,
        ...roles.map?.[action.roleId],
      }))
    : filteredRoleActions;
};

export const isMemberInCommunity = (member: Nullable<PublicMember>) => {
  if (!member) {
    return false;
  }

  const inCommunityStatuses = [PrivacyStatus.Public, PrivacyStatus.Unknown];

  return inCommunityStatuses.includes(member.privacyStatus);
};

const getIsDesignPreview = ({ site }: RootState) => {
  return site.settingsTab === DataHook.DesignTabButton;
};

const getIsResponsiveEditor = (state: RootState) => {
  const { booleans } = getStyleParams(state);
  return booleans?.responsive ?? booleans?.isResponsiveEditor ?? false;
};

const getShowCover = (state: RootState) => {
  return getStyleParams(state).booleans.showCover ?? false;
};

const getShowAsBlocked = ({ users }: RootState) => {
  const { current, viewed } = users;

  return (
    !!viewed.uid &&
    current?.uid !== viewed.uid &&
    viewed.roles.includes(RoleId.BLOCK_MEMBER)
  );
};

const hasBlogWriterProfileRoles = (roles: RoleId[]) => {
  const blogWriterProfileRoles = [
    RoleId.ADMIN,
    RoleId.SET_BLOG_WRITER,
    RoleId.SET_BLOG_EDITOR,
  ];

  return blogWriterProfileRoles.some((role) => roles.includes(role));
};

export const getShowTitle = (
  { site, users }: RootState,
  experiments: WixExperiments,
) => {
  const { installedApps } = site;
  const isBWPEnabled = experiments.enabled(Experiments.BWProfile);
  const isBlogInstalled = installedApps.wixBlog ?? false;

  return (
    isBWPEnabled &&
    isBlogInstalled &&
    hasBlogWriterProfileRoles(users.viewed.roles)
  );
};

const getCanEdit = ({ users }: RootState) =>
  users.viewed.rolesActions?.some(({ roleId }) => roleId === RoleId.EDIT) ??
  false;

const getAllowChat = (flowAPI: FlowAPI, state: RootState) => {
  const { booleans } = getStyleParams(state);
  const showMessageButtonParam = flowAPI.environment.isMobile
    ? booleans.showMessageButtonMobile
    : booleans.showMessageButton;
  const showMessageButton = showMessageButtonParam ?? true;

  return showMessageButton && state.site.isSocialChat;
};

export const getComputedProps = (
  flowAPI: FlowAPI,
  state: RootState,
  experiments: WixExperiments,
): InjectedComputedProps => ({
  profileLayout: getProfileLayout(state),
  pictureStyle: getProfileStyle(state),
  badgesSettings: getBadgeSettings(flowAPI.environment.language, state),
  rolesActions: getRolesActions(state),
  inCommunity: getInCommunity(state),
  isDesignPreview: getIsDesignPreview(state),
  isResponsiveEditor: getIsResponsiveEditor(state),
  showAsBlocked: getShowAsBlocked(state),
  showCover: getShowCover(state),
  showTitle: getShowTitle(state, experiments),
  canEdit: getCanEdit(state),
  allowChat: getAllowChat(flowAPI, state),
});
